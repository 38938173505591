import { Button } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggleIncidentReportingDepartmentDialog } from "../../../../app/slices/VarianceModuleSlice";
import { VarianceInitiateIndividualType } from "./visitor/VarianceInitiateIndividualType";
import { useParams } from "react-router";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";
import { editVariance } from "../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { VarianceInitiatePatientSearchDialog } from "./patient/VarianceInitiatePatientSearchDialog";
import { VarianceInitiateIdentification } from "./visitor/VarianceInitiateIndividual";
import { ReportingDepartment } from "./reportingDepartment/ReportingDepartment";

function VarianceInitiate() {
  const { center } = useParams();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const dispatch = useAppDispatch();
  dispatch(editVariance(new ReduxUpdate("center", center!)));
  const {data} = useMyProfileQuery();
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(data?.role === 'Admin'){return true;}
    if(data?.role === 'Quality Team'){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') !== undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentAdmin') !== undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentEdit') !== undefined){return true;}
    if(incident?.assignedUsers && incident?.assignedUsers.find(p => p.upn === data?.upn)){return true;}
    return false;
}


  return (
    <React.Fragment>
      {canEdit() && (
      <Button
        variant="contained"
        sx={{ background: "#62BCBD" }}
              
              onClick={() => dispatch(toggleIncidentReportingDepartmentDialog()) }
      >
        Create Incident
      </Button>)}
      <ReportingDepartment />
      <VarianceInitiateIndividualType />
      <VarianceInitiatePatientSearchDialog />
      <VarianceInitiateIdentification />
    </React.Fragment>
  );
}
export { VarianceInitiate };
