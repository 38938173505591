import { Alert, Grid, Stack } from '@mui/material';
import { useMyProfileQuery } from '../../../services/profileService/profile-api';
import { IVCard,CenterInfoCard, RadiologyCard, ReportsCard, ContractsCard, RefundsCard, ScheduleCard, CaseOptimizationCard, CaseManagementCard, IncidentReportingCard, RevRecCard} from '../features';
import { Loading } from '../../shared';
import './Home.css'
function Home(){
    const {data,isLoading} = useMyProfileQuery();
    return <div>
        <br />
        <br />
        {isLoading && 
            <Loading/>
        }
        {data?.role === 'No Role' && data?.claimsAccess.length <1 &&
            <div>
                <Alert color='warning'>You do not have access to any modules in this application.</Alert>
            </div>
        }
        
        <Grid container spacing={2}>
            <IVCard />
            <ReportsCard/>
            <RadiologyCard/>
            <CenterInfoCard />
            <ContractsCard/>
            <RefundsCard/>
            <ScheduleCard/>
            <CaseOptimizationCard/>
            <CaseManagementCard/>
            {!isLoading && <IncidentReportingCard myProfile={data!}/>}
            <RevRecCard/>
        </Grid>
    </div>
}

export {Home}
