import { DefaultMedicareConfig, MedicareConfig } from "./MedicareConfig";
import { MedicareRate } from "./MedicareRate";

export interface Center{
    id:string;
    code:string;
    name: string;
    shortName: string;
    fullName: string;
    pas:string;
    pasDescription:string;
    centerType:string;
    centerTypeDescription:string;
    streetAddress1:string;
    streetAddress2:string;
    city:string;
    state:string;
    zip:string;
    npi:string;
    taxId:string;
    sageLocation:string;
    authNotificationAddress:string;
    medicareConfig:MedicareConfig;
    medicareRates:MedicareRate[];
    allowManualScheduling:boolean;
    isInactive:boolean;
}

export function DefaultCenter():Center{
    return {
        id:'',code:'',name:'',shortName:'',fullName:'',pas:'',
        pasDescription:'',centerType:'',centerTypeDescription:'',
        streetAddress1:'',streetAddress2:'',city:'',state:'',zip:'',
        npi:'',taxId:'',authNotificationAddress:'',medicareRates:[], medicareConfig:DefaultMedicareConfig(),
        sageLocation:'', allowManualScheduling:false, isInactive:false
    }
}