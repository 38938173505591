import { Verification } from "../../../services/verifyService/model";
import { CompositeSpecification } from "../../../utils/Specification";

export class ACDFAlert extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        let alert = false;
        candidate.procedures.forEach(procedure => {
            if(procedure.code === '22551' && candidate.procedures.filter(u => u.code === '22552').length > 1 
                && !candidate.procedures.some(u => u.code === '22846')) {
                alert = true;
            }
        });
        return alert;
    }
}