export const IncorrectProcedureOptions = [
    {
        description: "Procedure Performed Incorrectly",
        name: "ProcedurePerformedIncorrectly",
    },
    {
        description: "Procedure Not Completed",
        name: "ProcedureNotCompleted",
    },
    {
        description: "Omission of Procedure",
        name: "OmissionOfProcedure",
    },
    {
        description: "Preparation Incorrect",
        name: "PreparationIncorrect",
    },
];
