import { Dialog, DialogContent,TextField, Typography, MenuItem, DialogTitle, DialogActions, Button, Switch } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { toggleCenterEditModal } from "../../../../app/slices/OrgModuleSlice";
import { editCenter } from "../../../../services/orgService/model/center/CenterSlice";
import { useUpdateCenterMutation } from "../../../../services/orgService/org-api";

function CenterEditModal(){
    const centerEditModal = useAppSelector((state) => state.orgModule.centerEditModal);
    const dispatch = useAppDispatch();
    const center = useAppSelector((state) => state.center.center);
    const handleChange = (e:any)=>{
        const {name,value} = e.target;
        let newCenter = center;
        dispatch(editCenter({...newCenter,[name]:value}));
    }
    const [updateCenter] = useUpdateCenterMutation();
    return <React.Fragment>
        <Dialog open={centerEditModal}>
            <DialogTitle>Edit Center</DialogTitle>
            <DialogContent>
                <Stack gap={2} sx={{marginTop:'5px'}}>
                    <TextField label='Name' name='name' value={center.name} onChange={handleChange} fullWidth size='small' sx={{width:'500px'}}/>
                    <TextField label='Short Name'name="shortName" value={center.shortName} onChange={handleChange} fullWidth size='small'/>
                </Stack>
                <Typography variant='h6' sx={{marginTop:'10px',marginBottom:'5px'}}>Attributes</Typography>
                <Stack direction='row' gap={2}>
                    <TextField label='PAS' select name='pas' value={center.pas} onChange={handleChange} size='small'>
                        <MenuItem value='UNK'>UNK</MenuItem>
                        <MenuItem value='CPSI'>CPSI</MenuItem>
                        <MenuItem value='HST'>HST</MenuItem>
                    </TextField>
                    <TextField label='Type' select name='centerType' value={center.centerType} onChange={handleChange} size='small'>
                        <MenuItem value='UNK'>Unknown</MenuItem>
                        <MenuItem value='HOS'>Hospital</MenuItem>
                        <MenuItem value='ASC'>Surgery Center</MenuItem>
                        <MenuItem value='PTC'>Physical Therapy</MenuItem>
                    </TextField>
                    <TextField label='Npi' name='npi' value={center.npi} onChange={handleChange} size='small'
                        InputLabelProps={{shrink:true}} sx={{width:'125px'}}/>
                    <TextField label='Tax ID' name='taxId' value={center.taxId} onChange={handleChange} size='small'
                        InputLabelProps={{shrink:true}} sx={{width:'125px'}}/>
                </Stack>
                <Typography variant='h6' sx={{marginTop:'10px',marginBottom:'5px'}}>Address</Typography>
                    <Stack gap={1}>
                        <TextField label='Address 1' name='streetAddress1' value={center.streetAddress1} onChange={handleChange} size='small' fullWidth/>
                        <TextField label='Address2' name='streetAddress2' value={center.streetAddress2} onChange={handleChange} size='small' fullWidth/>
                    </Stack>
                    <Stack direction='row' gap={2} sx={{marginTop:'10px'}}>
                        <TextField label='City' name='city' value={center.city} onChange={handleChange} size='small'/>
                        <TextField label='State' name='state' value={center.state} onChange={handleChange} size='small'/>
                        <TextField label='Zip Code' name='zip' value={center.zip} onChange={handleChange} size='small'/>
                    </Stack>
                    <Stack direction='row' alignItems='center' gap={1} sx={{marginTop:'10px'}}>
                        <Typography>Inactive Center</Typography>
                        <Switch checked={center.isInactive} onChange={(e) => handleChange({ target: { name: 'isInactive', value: e.target.checked } })} />
                    </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>dispatch(toggleCenterEditModal())} color='secondary'>Close</Button>
                <Button onClick={()=>{updateCenter(center); dispatch(toggleCenterEditModal());}}>Save</Button>

            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export{CenterEditModal}