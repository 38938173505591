import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editVariance } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { ReduxUpdate } from "../../../../../utils/ReduxUpdateCommands";
import { DirectorResponseInstructions } from "./DirectorResponseInstuctions";
import { TextField } from "@mui/material";


function DirectorResponseEditor(props: { canEdit: boolean }) {
  const dispatch = useAppDispatch();
  const description = useAppSelector(
    (state) => state.varianceRequest.varianceRequest.directorResponse
  );
  const [value, setValue] = useState(description);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    dispatch(
      editVariance(new ReduxUpdate('directorResponse', e.target.value))
    );
  };
  return (
    <React.Fragment>
      <DirectorResponseInstructions />
      <TextField
        multiline
        rows={20}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        disabled={!props.canEdit}
        placeholder="Please enter your response"
      />
    </React.Fragment>
  );
}

export { DirectorResponseEditor };
