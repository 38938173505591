import React from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { VarianceDetailsCommands } from "../features/details/commands/StatusChanges/VarianceDetailsCommands";
import { VarianceHeader } from "../features/details/Header/VarianceHeader";
import { IndividualDetails } from "../features/details/IndividualDetails";
import { setTabStripPosition } from "../../../app/slices/VarianceModuleSlice";
import {
  useFindVarianceRequestQuery,
  useVarianceSaveMutation,
} from "../../../services/varianceService/variance-api";
import { Loading } from "../../shared";
import { FallDetails } from "../features/details/IncidentType/Fall/FallDetails";
import { VariancePostCommand } from "../../../services/varianceService/commands/VariancePostCommand";
import { MedicationEvent } from "../features/details/IncidentType/MedicationEvent/MedicationEvent";
import { AdverseDrug } from "../features/details/IncidentType/AdverseDrug/AdverseDrug";
import { IncidentSubTypes } from "../features/details/IncidentType/IncidentSubTypes";
import { AirwayOptions } from "../features/details/IncidentType/Airway/AirwayOptions";
import { AnesthesiaOptions } from "../features/details/IncidentType/Anesthesia/AnesthesiaOptions";
import { BloodProductOptions } from "../features/details/IncidentType/BloodProduct/BloodProductOptions";
import { CodeBlueOptions } from "../features/details/IncidentType/CodeBlue/CodeBlueOptions";
import { ConsentOptions } from "../features/details/IncidentType/Consents/ConsentOptions";
import { DelayOfCareOptions } from "../features/details/IncidentType/DelayOfCare/DelayOfCareOptions";
import { TreatmentOptions } from "../features/details/IncidentType/Treatment/Treatment";
import { DietaryOptions } from "../features/details/IncidentType/Dietary/Dietary";
import { DischargeOptions } from "../features/details/IncidentType/Discharge/DischargeOptions";
import { EquipmentOptions } from "../features/details/IncidentType/Equipment/EquipmentOptions";
import { HealthCareITOptions } from "../features/details/IncidentType/HealthCareIT/HealthCareIT";
import { HospitalAcquiredOptions } from "../features/details/IncidentType/HospitalAcquired/HospitalAcquiredOptions";
import { ImagingOptions } from "../features/details/IncidentType/Imaging/Imaging";
import { InfectionOptions } from "../features/details/IncidentType/Infection/InfectionOptions";
import { IVOptions } from "../features/details/IncidentType/IV/IV";
import { LabOptions } from "../features/details/IncidentType/Lab/LabOptions";
import { MaterialsOptions } from "../features/details/IncidentType/Materials/MaterialsOptions";
import { PatientIDOptions } from "../features/details/IncidentType/PatientID/PatientIDOptions";
import { PhysicianBehaviorOptions } from "../features/details/IncidentType/PhysicianBehavior/PhysicianBehaviorOptions";
import { AdmissionConditionOptions } from "../features/details/IncidentType/AdmissionCondition/AdmissionConditionOptions";
import { PropertyLossOptions } from "../features/details/IncidentType/PropertyLoss/PropertyLossOptions";
import { ProvisionOfCareOptions } from "../features/details/IncidentType/ProvisionOfCare/ProvisionOfCareOptions";
import { SafetyOptions } from "../features/details/IncidentType/Safety/SafetyOptions";
import { SterileOptions } from "../features/details/IncidentType/Sterile/SterileOptions";
import { DiagnosticTestErrorOptions } from "../features/details/IncidentType/DiagnosticTestError/DiagnosticTestErrorOptions";
import { FacilitiesOptions } from "../features/details/IncidentType/Facilities/FacilitiesOptions";
import { SugicalProcedureDetails } from "../features/details/IncidentType/SurgicalProcedure/SurgicalProcedureDetails";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { IncidentComments } from "../features/details/comments/IncidentComments";
import { Alert, Table } from "@mui/material";
import { IncidentDescription } from "../features/details/description/IncidentDescription";
import { DirectorResponse } from "../features/details/directorResponse/DirectorResponse";
import { ImmediateFollowUp } from "../features/details/followup/FollowUp";
import { WitnessSection } from "../features/details/witnesses/WitnessSection";
import { Severity } from "../features/details/severity/Severity";
import { VarianceDocumentList } from "../features/details/commands/Documents/VarianceDocumentLIst";

function VarianceRequestDetail() {
  const dispatch = useAppDispatch();
  const [varianceSave, status] = useVarianceSaveMutation();
  const { Id } = useParams();
  const { isLoading } = useFindVarianceRequestQuery(Id!);
  const tabIndex = useAppSelector(
    (state) => state.varianceModule.TabStripPosition
  );
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const toggleTab = (e: TabStripSelectEventArguments) =>
    dispatch(setTabStripPosition(e.selected));
const myProfile = useMyProfileQuery();
const canSave = myProfile.data?.role === "Admin";
  const handleSave = () => {
    if(canSave){
    let command = new VariancePostCommand(incident);
    varianceSave(command)
      .unwrap()
      .then(() => {});
    }
  };
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(myProfile.data?.role === 'Admin'){return true;}
    if(myProfile.data?.role === 'Quality Team'){return true;}
    if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
    if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentEdit' && incident.status === "Started") != undefined){return true;}
    if(incident?.assignedUsers && incident?.assignedUsers.find(p => p.upn === myProfile.data?.upn)){return true;}
    return false;
  }
  const isDirectorTabEnabled = (): boolean => {
    if (myProfile.data?.role === "Admin" && incident.incidentType !== "Started") {
      return true;
    }
    if (myProfile.data?.role === "Quality Team" && incident.incidentType !== "Started") {
      return true;
    }
    if (myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") != undefined && incident.incidentType !== "Started") {
      return true;
    }
    if (myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentQualityTeam") != undefined && incident.incidentType !== "Started") {
    return true;
  }
    return false;
  };

  return (
    <React.Fragment>
      {isLoading && (
        <h5>
          <Loading />
        </h5>
      )}
      {!isLoading && (
        <div>
          <VarianceDetailsCommands />
          <VarianceHeader />
          <IndividualDetails />
          <TabStrip
            selected={tabIndex}
            onSelect={(e) => {
              toggleTab(e);
              {
                handleSave();
              }
            }}
          >

            <TabStripTab title="Incident">
              {typeof incident.incidentType !== 'string' || incident.incidentType === "UNKNOWN" && 
                <React.Fragment>
                  <Alert color='warning'>Please choose an Incident Type above.</Alert>
                  <Table />
                </React.Fragment>}
              {incident.incidentType === "ADVERSE_DRUG" && <AdverseDrug canEdit={canEdit()} />}
              {incident.incidentType === "ANESTHESIA" && (
                <IncidentSubTypes
                  options={AnesthesiaOptions}
                  formName="Anesthesia Event"
                  freeformName="AnesthesiaFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "AIRWAY" && (
                <IncidentSubTypes
                  options={AirwayOptions}
                  formName="Airway Management"
                  freeformName="AirwayFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "BLOOD_PRODUCT" && (
                <IncidentSubTypes
                  options={BloodProductOptions}
                  formName="Blood Product/Transfusion"
                  freeformName="BloodProductFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "CODE_BLUE" && (
                <IncidentSubTypes
                  options={CodeBlueOptions}
                  formName="Code Blue/Rapid Response"
                  freeformName="CodeBlueFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "COMPLAINT" && (
                <IncidentSubTypes
                  options={[
                    { description: "Complaint", name: "Complaint" },
                    { description: "Grievance", name: "Grievance" },
                  ]}
                  formName="Complaints/Grievances"
                  freeformName="ComplaintFreeForm"
                  canEdit={canEdit()}
                />
              )}
              <Table />
              {incident.incidentType === "CONSENT" && (
                <IncidentSubTypes
                  options={ConsentOptions}
                  formName="Consents"
                  freeformName="ConsentFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "DELAY" && (
                <IncidentSubTypes
                  options={DelayOfCareOptions}
                  formName="Delay of Care"
                  freeformName="DelayOfCareFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "DIETARY" && (
                <IncidentSubTypes
                  options={DietaryOptions}
                  formName="Dietary"
                  freeformName="DietaryFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "DIAGNOSIS" && (
                <IncidentSubTypes
                  options={TreatmentOptions}
                  formName="Diagnosis/Treatment"
                  freeformName="TreatmentFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "DIAGNOSTIC_TEST" && (
                <IncidentSubTypes
                  options={DiagnosticTestErrorOptions}
                  formName="Diagnostic Test Error"
                  freeformName="DiagnosticTestErrorFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "DISCHARGE" && (
                <IncidentSubTypes
                  options={DischargeOptions}
                  formName="Discharge Issue"
                  freeformName="DischargeFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "ELOPEMENT" && (
                <IncidentSubTypes
                  options={[
                    { description: "Elopement", name: "Elopement" },
                    {
                      description: "AMA - Patient Left Against Medical Advise",
                      name: "AMA",
                    },
                    {
                      description: "LWBS - Patient Left Without Being Seen",
                      name: "LWBS",
                    },
                  ]}
                  formName="Elopement"
                  freeformName="ElopementFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "EQUIPMENT_FAILURE" && (
                <IncidentSubTypes
                  options={EquipmentOptions}
                  formName="Equipment/Medical Device"
                  freeformName="EquipmentFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "FACILITIES" && (
                <IncidentSubTypes
                  options={FacilitiesOptions}
                  formName="Facilities"
                  freeformName="FacilitiesFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "FALL" && <FallDetails canEdit={canEdit()} />}
              {incident.incidentType === "FALL_WITH_HARM" && 
                <IncidentSubTypes
                  options={[]}
                  formName="Fall"
                  freeformName="FallFreeForm"
                  canEdit={canEdit()}
                />
              }
              {incident.incidentType === "HEALTHCARE_IT" && (
                <IncidentSubTypes
                  options={HealthCareITOptions}
                  formName="Healthcare IT"
                  freeformName="HealthCareITFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "HOSPITAL_ACQUIRED_CONDITION" && (
                <IncidentSubTypes
                  options={HospitalAcquiredOptions}
                  formName="Hospital Acquired Condition"
                  freeformName="HospitalAcquiredFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "IMAGING" && (
                <IncidentSubTypes
                  options={ImagingOptions}
                  formName="Imaging"
                  freeformName="ImagingFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "INFECTION_CONTROL" && (
                <IncidentSubTypes
                  options={InfectionOptions}
                  formName="Infection Control"
                  freeformName="InfectionFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "IV_ACCESS" && (
                <IncidentSubTypes
                  options={IVOptions}
                  formName="IV/Vascular Access Device"
                  freeformName="IVFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "LABORATORY" && (
                <IncidentSubTypes
                  options={LabOptions}
                  formName="Laboratory"
                  freeformName="LabFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "MEDICATION" && <MedicationEvent canEdit={canEdit()} />}
              {incident.incidentType === "MATERIALS_MANAGEMENT" && (
                <IncidentSubTypes
                  options={MaterialsOptions}
                  formName="Materials Management/Supply Chain"
                  freeformName="MaterialsFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "PATIENT_IDENTIFICATION" && (
                <IncidentSubTypes
                  options={PatientIDOptions}
                  formName="Patient Identification"
                  freeformName="PatientIdentificationFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "INJURY" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Injury"
                  freeformName="InjuryFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "PHYSICIAN_BEHAVIOR" && (
                <IncidentSubTypes
                  options={PhysicianBehaviorOptions}
                  formName="Physician Behavior"
                  freeformName="PhysicianFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "PRESENT_ON_ADMISSION" && (
                <IncidentSubTypes
                  options={AdmissionConditionOptions}
                  formName="Present on Admission Condition"
                  freeformName="PresentOnAdmissionFreeForm"
                  canEdit={canEdit()}
                />
              )}             
              {incident.incidentType === "PROPERTY_LOSS" && (
                <IncidentSubTypes
                  options={PropertyLossOptions}
                  formName="Property Loss"
                  freeformName="PropertyLossFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "PROVISION_OF_CARE" && (
                <IncidentSubTypes
                  options={ProvisionOfCareOptions}
                  formName="Provision of Care"
                  freeformName="ProvisionOfCareFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "READMISSION" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Re-admission Unplanned within 30 days"
                  freeformName="ReadmissionFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "RETAINED_FOREIGN_OBJECT" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Retained Foreign Object"
                  freeformName="RetainedForeignObjectFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "RETURN_TO_SURGERY" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Return to Surgery, Unplanned"
                  freeformName="ReturnToSurgeryFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "SAFETY" && (
                <IncidentSubTypes
                  options={SafetyOptions}
                  formName="Safety/Security"
                  freeformName="SafetyFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "STERILE_PROCESSING" && (
                <IncidentSubTypes
                  options={SterileOptions}
                  formName="Sterile Processing"
                  freeformName="SterileProcessingFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "SURGICAL_PROCEDURE" && (
                <SugicalProcedureDetails canEdit={canEdit()} />
              )}
              {incident.incidentType === "SURGICAL_SITE_INFECTION" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Surgical Site Infection"
                  freeformName="SurgicalSiteInfectionFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "UTILIZATION_REVIEW" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Utilization Review/Case Management"
                  freeformName="UtilizationReviewFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "WRONG_PROCEDURE" && (
                <IncidentSubTypes
                options={[
                  { description: "Wrong Side", name: "WrongSide" },
                  { description: "Wrong Site", name: "WrongSite"},
                  { description: "Wrong Patient", name: "WrongPatient" },
                ]}
                  formName="Wrong Procedure"
                  freeformName="WrongProcedureFreeForm"
                  canEdit={canEdit()}
                />
              )}
              {incident.incidentType === "OTHER" && (
                <IncidentSubTypes
                  options={[]}
                  formName="Other Event"
                  freeformName="OtherFreeForm"
                  canEdit={canEdit()}
                />
              )}
            </TabStripTab>
            <TabStripTab title="Severity">
              <Table />
              <Severity canEdit={canEdit()}/>
            </TabStripTab>
            <TabStripTab title="Description">
              <Table />
              <IncidentDescription />
            </TabStripTab>
            <TabStripTab title="Witnesses">
              <Table />
              <WitnessSection />
            </TabStripTab>
            <TabStripTab title="Follow Up">
              <Table />
              <ImmediateFollowUp />
            </TabStripTab>
            {isDirectorTabEnabled() && (
              <TabStripTab title="Director Response">
                <Table />
                <DirectorResponse />
              </TabStripTab>
            )}
            <TabStripTab title="Comments">
              <Table />
              <IncidentComments />
            </TabStripTab>
          <TabStripTab title="Documents">
              <Table />
              <VarianceDocumentList />
            </TabStripTab>
          </TabStrip>
        </div>
      )}
    </React.Fragment>
  );
}

export { VarianceRequestDetail };
