import React, { useEffect, useState } from "react"
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useAddAccessMutation, useAddClaimMutation, useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import { useCenterListQuery } from "../../../../../services/orgService/org-api";
import { AccessAddCommand, ClaimAddCommand } from "../../../../../services/profileService/commands";
import { Loading } from "../../../../shared";
import { useNavigate } from "react-router";

type ownProps={
    navigateTo: string;
}

function CenterSelectorNoAccess(props:ownProps){
    const profile = useMyProfileQuery();
    const {data} = useCenterListQuery();
    const [incidentEditAccess] = useAddClaimMutation();
    useEffect(() => {
        if (profile?.data?.identity && profile?.data?.role !== 'FullCircle User' && profile?.data?.role !== 'TrueBridge User') {
            const command = new ClaimAddCommand(profile?.data?.identity, 'IncidentEdit');
            incidentEditAccess(command);
        }
    }, [incidentEditAccess, profile?.data]);
    const [addAccess, status] = useAddAccessMutation();
    const handleSave=()=>{
        const command = new AccessAddCommand(profile?.data?.identity!, selectedCenter!, data?.find(p=>p.code === selectedCenter)?.name!);
        addAccess(command);
    }
    const [selectedCenter, setSelectedCenter] = useState('Unk');    
    const sortedData = data?.filter(center => !center.isInactive).slice().sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Stack alignItems='center' gap={2}>
                <Typography variant='inherit'>Incident Reporting</Typography>
                <Typography variant='h6'>Select a Center</Typography>
                <Stack direction='row' justifyContent='center' justifyItems='center' gap={1}>
                    <TextField select sx={{width:'500px',background:'white','& legend': { display: 'none' }, '& fieldset': { top: 0 }}} 
                        value={selectedCenter} 
                        onChange={(e)=>setSelectedCenter(e.target.value)}>
                        <MenuItem value='Unk'> </MenuItem>
                        {sortedData?.map(i => (
                            <MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>
                        ))}   
                    </TextField>
                    <Button variant="outlined" onClick={handleSave}>Select</Button>
                </Stack>
                {status.isLoading && <Loading/>}
                {status.isSuccess && navigate(props.navigateTo + '/' + selectedCenter)}
            </Stack>
        </React.Fragment>
    );
}

export {CenterSelectorNoAccess}