import React, { useEffect } from "react";
import { useAddClaimMutation, useMyProfileQuery } from "../../../../services/profileService/profile-api";
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import VarianceIcon from '../../../../images/appicons/Variance.png';
import { ClaimAddCommand } from "../../../../services/profileService/commands";
import { Profile } from "../../../../services/profileService";

type ownProps={
    myProfile: Profile;
}
function IncidentReportingCard(props:ownProps){
    const [access, status] = useAddClaimMutation();

    useEffect(() => {
        if (props.myProfile?.identity && props.myProfile?.role !== 'FullCircle User' && props.myProfile?.role !== 'TrueBridge User') {
            const command = new ClaimAddCommand(props.myProfile.identity, 'IncidentEdit');
            access(command);
        }
    }, [access, props.myProfile]);

    return (
        <React.Fragment>
            { props.myProfile?.role !== 'FullCircle User' && props.myProfile?.role !== 'TrueBridge User' &&
            <Grid item sm={3}>
                <Card className='card-img-body center-align'>
                    <CardContent className='card-img-main'>
                        <a className='btn img-button' href={'/VarianceHome'}>
                            <img className='card-img' src={VarianceIcon} alt='' />
                        </a>
                    </CardContent>
                    <CardActions className='card-img-footer'>
                        <h6>Incident Reporting</h6>
                    </CardActions>
                </Card>
            </Grid>
            }
        </React.Fragment>
    );
}

export { IncidentReportingCard };